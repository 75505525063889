import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
    Grid,
    Paper,
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Box,
    Collapse,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { formatter } from '../../../utils/formatter';
import {
    BoxContainer,
    Button,
    CircularProgress,
    CodeBlock,
    CollapsibleRowHeader,
    FormControl,
    TextField,
} from '../styled';

interface DataProps {
    processing?: boolean;
    // apiStatus?: number;
    onSubmit: (userId: string) => Promise<any[]>;
}

const Row = ({ row }) => {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <CollapsibleRowHeader>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{row.memo}</TableCell>
                <TableCell align="right">{row.transactions.length}</TableCell>
                <TableCell align="right">{row.interval}</TableCell>
                <TableCell align="right">{Number(row.standardDeviation).toFixed(3)}</TableCell>
                <TableCell align="right">{`${(Number(row.relativeStandardDeviation) * 100).toFixed(
                    3
                )}%`}</TableCell>
                <TableCell align="right">{formatter.format(row.transactions[0].value)}</TableCell>
                <TableCell align="right">
                    <span style={{ whiteSpace: 'nowrap' }}>{row.transactions[0].transactedAt}</span>
                </TableCell>
            </CollapsibleRowHeader>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Transactions
                            </Typography>
                            <CodeBlock>
                                <pre>
                                    {JSON.stringify(
                                        row.transactions[0].personalFinanceCategory,
                                        null,
                                        2
                                    )}
                                </pre>
                            </CodeBlock>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Date Diff</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.transactions.map((txn, idx) => (
                                        <TableRow key={txn.transactedAt}>
                                            <TableCell component="th" scope="row">
                                                {txn.transactedAt}
                                            </TableCell>
                                            <TableCell>
                                                {idx > 0
                                                    ? moment(
                                                          row.transactions[idx - 1].transactedAt
                                                      ).diff(moment(txn.transactedAt), 'days')
                                                    : 0}
                                            </TableCell>
                                            <TableCell align="right">
                                                {formatter.format(txn.value)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default ({ onSubmit, processing }: DataProps) => {
    const [userId, setUserId] = useState('');
    const [transactions, setTransactions] = useState<any[]>([]);
    const resetForm = useCallback(() => {
        setUserId('');
        const clearButtons = document.getElementsByClassName(
            'MuiAutocomplete-clearIndicator'
        ) as unknown as HTMLButtonElement[];
        clearButtons[0].click();
        clearButtons[1].click();
    }, []);

    const submit = useCallback(async () => {
        if (userId) {
            const result = await onSubmit(userId);
            if (result) {
                setTransactions(result);
            }
        }
    }, [userId, resetForm]);

    return (
        <>
            <Helmet>
                <title>Hiatus Tools | Recurring Transactions</title>
            </Helmet>

            <BoxContainer container spacing={3}>
                <Grid item xs={12} sm={12} md={9}>
                    <Paper elevation={1}>
                        <Container>
                            <Typography variant="h6">Get Recurring</Typography>

                            <FormControl variant="outlined">
                                <TextField
                                    onChange={e => setUserId(e.target.value)}
                                    variant="outlined"
                                    disabled={processing}
                                    placeholder="User ID"
                                    size="small"
                                    value={userId}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submit}
                                    disabled={!userId}
                                >
                                    {processing ? <CircularProgress color="inherit" /> : 'Submit'}
                                </Button>
                            </FormControl>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Memo</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                            <TableCell align="right">Interval</TableCell>
                                            <TableCell align="right">Std Dev</TableCell>
                                            <TableCell align="right">Relative Std Dev</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                            <TableCell align="right">Last Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions
                                            .sort((a, b) =>
                                                moment(b.transactions[0].transactedAt).diff(
                                                    moment(a.transactions[0].transactedAt)
                                                )
                                            )
                                            .map(row => (
                                                <Row
                                                    key={`${row.memo} ${row.transactions[0].transactedAt}`}
                                                    row={row}
                                                />
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </Paper>
                </Grid>
            </BoxContainer>
        </>
    );
};
