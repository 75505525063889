import * as api from '../../../services/api';

export const getUser = async (userId: string) => {
    const { status, body } = await api.get({
        path: `hiatus/identity-users-manage/user/${userId}`,
    });
    return { status, body };
};

export const getRecurring = async (userId: string) => {
    const { status, body } = await api.post({
        path: `hiatus/accounts-search/user/${userId}/recurring`,
    });
    return { status, body };
};
